import React from "react";
import "./App.scss";

function App() {
  return (
    <div className="app">
      <div className="callMyLawyer">
        <a>
          <h1 className="call">Call My</h1>
          <h1 className="lawyer">Lawyer</h1>
          <h3 className="phone">1(818)839-7800</h3>
        </a>
      </div>
    </div>
  );
}

export default App;
